export const socials = [
  {
    href: "https://discord.com/users/902801093950582784",
    name: "Discord",
    description: "adrea#6661",
    logo: <i className="fa-brands fa-discord fa-2x"></i>
  },
  {
    href: "https://github.com/adreaisbad",
    name: "GitHub",
    description: "adreaisbad",
    logo: <i className="fa-brands fa-github fa-2x"></i>
  },
  {
    href: "https://www.youtube.com/channel/UCJ5xtc8kAjQwsg6do7jBjqw",
    name: "YouTube",
    description: "Maybe subscribe?",
    logo: <i className="fa-brands fa-youtube fa-2x"></i>
  },
];
